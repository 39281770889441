<template>
  <v-container
    class="pa-0 pr-4"
    fluid
  >
    <FilterHeader
      @filter="filterAction"
      @clear="clearAction"
    />

    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="filter.name"
          outlined
          label="Nome"
          rounded
          dense
          hide-details
        />
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="filter.description"
          outlined
          label="Descrição"
          rounded
          dense
          hide-details
        />
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="filter.vendor"
          outlined
          label="Fornecedor"
          rounded
          dense
          hide-details
        />
      </v-col>

      <v-col cols="12">
        <v-select
          v-model="filter.status"
          :items="status"
          item-text="title"
          item-value="value"
          label="Status"
          outlined
          rounded
          dense
          hide-details
        />
      </v-col>

      <v-col cols="12">
        <v-icon
          color="primary"
          small
          >fas fa-calendar-alt</v-icon
        >
        <span class="text-body-2"> Data de cadastro </span>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="filter.date_from"
          label="De"
          v-mask="['##/##/####']"
          dense
          outlined
          rounded
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="filter.date_to"
          label="Até"
          v-mask="'[##/##/####]'"
          dense
          outlined
          rounded
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FilterHeader from '@/components/FilterHeader'

export default {
  components: { FilterHeader },
  data: () => ({
    filter: {},
    status: [
      { title: 'Inativo', value: false },
      { title: 'Ativo', value: true },
    ],
  }),
  methods: {
    filterAction() {
      this.$emit('filter', this.filter)
    },
    clearAction() {
      this.$emit('clear')
      this.filter = {}
    },
  },
}
</script>
